import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

// Vrac position and rotation
const _vracPosition = {
  x: 0.0016,
  y: 0.073,
  z: -0.001,
};

const _vracRotation = {
  x: -0.02,
  y: -12,
  z: 0.0205,
};

// In this file, we will load the vrac model and apply the materials
// Note that this is quite heavy, with all the meshes and textures

export default function loadTeaObject(parent) {
  const objLoader = new OBJLoader();

  objLoader.load("vrac/model.obj", (object) => {
    parent.add(object);

    // Set position and rotation
    object.rotation.set(_vracRotation.x, _vracRotation.y, _vracRotation.z);
    object.position.set(_vracPosition.x, _vracPosition.y, _vracPosition.z);

    // Tea
    const teaMaterial = new THREE.MeshStandardMaterial({
      color: new THREE.Color(1, 1, 1),
      metalness: 0.054545,
      roughness: 1,
      map: new THREE.TextureLoader().load("vrac/diff1024.webp"),
      transparent: true,
      opacity: 1,
      displacementMap: new THREE.TextureLoader().load("vrac/disp1024.webp"),
      bumpMap: new THREE.TextureLoader().load("vrac/bump1024.webp"),
      flatShading: false,
    });

    teaMaterial.bumpScale = 0.1055;
    teaMaterial.displacementScale = 0.0035;

    teaMaterial.displacementMap.wrapS = teaMaterial.displacementMap.wrapT =
      THREE.RepeatWrapping;
    teaMaterial.displacementMap.magFilter =
      teaMaterial.displacementMap.minFilter = THREE.LinearFilter;
    teaMaterial.displacementMap.format = THREE.RGBAFormat;
    teaMaterial.displacementMap.type = THREE.UnsignedByteType;

    teaMaterial.displacementMap.wrapS = teaMaterial.displacementMap.wrapT =
      THREE.RepeatWrapping;
    teaMaterial.displacementMap.repeat.set(1, 1);
    teaMaterial.displacementMap.offset.set(0, 0);
    teaMaterial.displacementMap.center.set(0.5, 0.5);
    teaMaterial.displacementMap.rotation = 0;

    // first child is the actual "vrac"
    const child1 = object.children[0];
    child1.material = teaMaterial;

    // move the vrac a bit down
    child1.position.setY(child1.position.y - 0.0035);

    // Branches
    const barkMaterial = new THREE.MeshStandardMaterial({
      color: new THREE.Color(1, 1, 1),
      metalness: 0.054545,
      roughness: 1,
      map: new THREE.TextureLoader().load("vrac/bark512.webp"),
      bumpMap: new THREE.TextureLoader().load("vrac/barkBump512.webp"),
      transparent: true,
      opacity: 1,
    });
    barkMaterial.bumpScale = 0.055;
    for (let i = 1; i < object.children.length; i++) {
      object.children[i].material = barkMaterial;
    }

    // clone child1: this is a dirty hack to get the displacement map working
    // we don't apply the displacement map on this object that much, se it stays a bit "under" the displaced one
    // and we see less transparent/dirty pixels
    // it kind of acts as a background
    const child2 = child1.clone();
    child2.position.setY(-0.002);
    object.add(child2);
    const child2Material = child2.material.clone();
    child2Material.displacementScale = 0.0006;
    child2.material = child2Material;
  });
}
